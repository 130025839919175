
export default {
  name: 'Home',
  data() {
    return {
      posterImage: require('~/assets/video_poster/home_poster.jpg'),
      // bannerImg: 'https://ec-cstatic.akulaku.net/web-site/_nuxt/img/pc_home_banner_bg-2x.dd18ddd.jpg',
      advantage: [
        {
          icon: require('~/assets/images/home/advantage_1.svg'),
          name: '为客户提供所需帮助',
          desc: ['与网站访客沟通交流，解答咨询问题。80%以上案例都能现场解决，无需继续跟进。'],
        },
        {
          icon: require('~/assets/images/home/advantage_2.svg'),
          name: '心中有数',
          desc: ['客服表现如何，做到心里有数，完全掌握客服状态。从每次聊天评价中，汲取问题，从而不断提升服务质量。'],
        },
        {
          icon: require('~/assets/images/home/advantage_3.svg'),
          name: '多渠道聊天',
          desc: ['同个面板管理多个渠道的咨询，操作简单，集成聊天插件，Messenger、WhatsApp、Instagram、Telegram等'],
        },
        {
          icon: require('~/assets/images/home/advantage_4.svg'),
          name: '超越传统电话和电子邮件，即问即达',
          desc: ['告别漫长等待，给客户即时回复的惊喜体验。'],
        },
        {
          icon: require('~/assets/images/home/advantage_5.svg'),
          name: '解决客户问题，提升客户满意度',
          desc: [
            '服务更高效：快捷回复、内部知识库、消息提醒，提升30%人效。',
            '全程数据监控：实时监控，数据报表全面细致，服务过程100%掌握。',
          ],
        },
        {
          icon: require('~/assets/images/home/advantage_6.svg'),
          name: '多客服支持',
          desc: [
            '统一管理，提升在线客服效率，把握客户咨询，提升商机转化率。智能客服7*24小时为您的客户服务，可解决40%咨询量，减少工作量',
          ],
        },
      ],
      scenario: [
        {
          img: require('~/assets/images/home/scenario_1.png'),
          icon: require('~/assets/images/home/scenario_icon_1.svg'),
          name: '电商',
        },
        {
          img: require('~/assets/images/home/scenario_2.png'),
          icon: require('~/assets/images/home/scenario_icon_2.svg'),
          name: '电子银行',
        },
        {
          img: require('~/assets/images/home/scenario_3.png'),
          icon: require('~/assets/images/home/scenario_icon_3.svg'),
          name: '其他',
        },
      ],
      xPotionPercent: 0,
    }
  },
  methods: {
    onScroll(e) {
      const { scrollLeft, scrollWidth } = e.target
      this.xPotionPercent = ((scrollLeft / scrollWidth) * 10).toFixed(2)
    },
    // 视频播放完成回到初始状态
    onVideoEnd(e) {
      const currentSrc = e.target.currentSrc
      e.target.src = ''
      e.target.src = currentSrc
    },
  },
}
